import { useEffect, useRef } from 'react'
import {
  NotificationsBellActiveIcon,
  Box,
  Circle,
  Flex,
  IconButton,
  NotificationsBellStandardIcon,
} from 'src/components/designsystem'
import { useRightSidebarStore } from 'src/components/layout/right-sidebar/right-sidebar-store'
import { useMarkNotificationAsRead, useNotificationHistory } from 'src/data/queries/notifications'
import getCurrentGroupId from 'src/utils/push-notifications/group-id/get-current-group-id'
import { pushNotificationsPluginWrapper } from 'src/utils/clients/native/wrappers/push-notifications-plugin-wrapper'
import { useAuth } from 'src/auth'
import create from 'zustand'
import { PushNotificationSchema } from '@capacitor/push-notifications'
import { isEsignPushNotification } from 'src/app/native-app-setup/push-notification-tap-listener'
import { useQueryClient } from '@tanstack/react-query'

export function NotificationHistoryHeaderButton() {
  const queryClient = useQueryClient()
  const shouldAllowMutationRef = useRef(false)
  const notificationsButtonRef = useRef()

  const { authenticated, slug } = useAuth()
  const { groupId, setNotificationGroupId } = useNotificationGroupIdStore()

  const { toggleRightSidebar, checkIfNotificationHistoryIsOpen } = useRightSidebarStore()
  const isComponentActive = checkIfNotificationHistoryIsOpen()
  const isComponentNotActive = !isComponentActive

  useEffect(() => {
    ;(async function () {
      const data = await getCurrentGroupId(authenticated)
      setNotificationGroupId(data)
    })()
  }, [groupId, setNotificationGroupId, authenticated])

  const notificationHistoryQuery = useNotificationHistory({
    groupId,
    slug,
  })

  const historicalNotifications = notificationHistoryQuery?.data?.data

  const markNotificationsAsRead = useMarkNotificationAsRead({ groupId })

  const hasUnreadNotifications = historicalNotifications?.some(
    (notification) => notification.readAt === null
  )

  const notificationIds = historicalNotifications
    ?.filter((notification) => notification.readAt === null)
    .map((notification) => notification.id)

  useEffect(() => {
    ;(async () => {
      const handleRefetchOnNewPushNotification = (notification: PushNotificationSchema) => {
        notification && notificationHistoryQuery.refetch()

        if (isEsignPushNotification(notification)) {
          return queryClient.invalidateQueries({ queryKey: ['esign-reminders'] })
        }
      }

      const notificationReceiveListener = await pushNotificationsPluginWrapper.addListener(
        'pushNotificationReceived',
        handleRefetchOnNewPushNotification
      )

      return () => {
        notificationReceiveListener.remove()
      }
    })()
  }, [notificationHistoryQuery, queryClient])

  if (isComponentActive) {
    shouldAllowMutationRef.current = true
  }
  if (
    shouldAllowMutationRef.current &&
    isComponentNotActive &&
    groupId &&
    notificationIds?.length > 0
  ) {
    markNotificationsAsRead.mutate({ groupId, notificationIds })
    shouldAllowMutationRef.current = false
  }

  return (
    <Flex>
      <IconButton
        variant="ghostSecondary"
        color="gray.500"
        ref={notificationsButtonRef}
        isRound
        h={8}
        w={8}
        minW={8}
        aria-label="Notifications"
        icon={
          <NotificationIcon
            isActive={isComponentActive}
            hasUnreadNotifications={hasUnreadNotifications}
            isRefetching={notificationHistoryQuery?.isRefetching}
            isLoading={markNotificationsAsRead?.isPending}
          />
        }
        onClick={() => toggleRightSidebar('notifications')}
      />
    </Flex>
  )
}

function NotificationIcon({ isActive, hasUnreadNotifications, isRefetching, isLoading }) {
  return isActive ? (
    <NotificationsBellActiveIcon fontSize="1.875rem" />
  ) : (
    <StandardNotificationsBellIcon
      hasUnreadNotifications={hasUnreadNotifications}
      isRefetching={isRefetching}
      isLoading={isLoading}
    />
  )
}

function StandardNotificationsBellIcon({ hasUnreadNotifications, isRefetching, isLoading }) {
  return !isRefetching && !isLoading && hasUnreadNotifications ? (
    <Box p={2}>
      <Circle
        bg="red"
        size={2}
        color="white"
        fontSize={3}
        fontWeight="normal"
        pos="absolute"
        top={1.5}
        left={4}
      />
      <NotificationsBellStandardIcon fontSize="1.25rem" />
    </Box>
  ) : (
    <NotificationsBellStandardIcon fontSize="1.25rem" />
  )
}

type NotificationGroupIdState = {
  groupId: string
  setNotificationGroupId: (id: string) => void
}

export const useNotificationGroupIdStore = create<NotificationGroupIdState>((set) => ({
  groupId: null,
  setNotificationGroupId: (id) => {
    set({ groupId: id })
  },
}))
