import { cardAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(cardAnatomy.keys)

// define custom styles for xl size
const sizes = {
  xs: definePartsStyle({
    header: {
      padding: 4,
    },
    body: {
      padding: 2,
    },
  }),
}

// export sizes in the component theme
export const CardStyleConfig = defineMultiStyleConfig({
  sizes,
})
